import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {Helmet} from 'react-helmet'
import AnalyticsLoader from "./components/AnalyticsLoader";
const App = () => {
  const isStartUpShowed = useSelector((state) => state.advs.isStartUpShowed);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const appTitle = process.env.REACT_APP_PWA_TITLE;
  if (searchParams?.get("promocode")) {
    localStorage.setItem("promocode", searchParams.get("promocode"));
  }
  if (!isStartUpShowed) {
    return (
      <Navigate
        to={`/startup?redirect=${pathname === "/" ? "/index" : pathname}${search}`}
        replace={true}
      />
    );
  }
  return (
    <div className="h-full w-full">
      <Helmet>
        <title>{appTitle}</title>
      </Helmet>
      <Outlet />
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname.indexOf("video/") !== -1 ||
            location.pathname.indexOf("startup") !== -1
            ? location.key
            : location.pathname;
        }} />
        <AnalyticsLoader />
    </div>
  );
};
export default App;
