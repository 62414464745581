import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getVideoPlayUrl,
  guessWhatILike,
  getVideoDetail,
  setAsfavorite,
  buyVideo,
} from "../apis";
import bg_lblogo from "../assets/home/bg_lblogo@3x.png";
import ic_time from "../assets/home/ic_time_def@3x.png";
import ic_heart from "../assets/video/heart.png";
import ic_instructor from "../assets/video/ic_instructor_gray@3x.png";
import ic_mute from "../assets/video/ic_mute_white@3x.png";
import ic_like from "../assets/video/ic_like_def@3x.png";
import { CardGrid } from "../components/CardGrid";
import VideoPreviewDialog from "../components/dialogs/VideoPreviewDialog";
import { secondsToHHMMSS } from "../utils/timeUtil";
import { getAdvByPosition } from "../store/actions/advsAction";
import { useDispatch, useSelector } from "react-redux";
import { PLAYER_ADV_POS } from "../config";
import { jumpUrl } from "../utils";
import { VideoJS } from "../components/Player";
import { Image } from "../components/Image";
import useTimer from "../hooks/useTimer";
import {
  addVideoToFavorites,
  addVideoToWatchHistory,
} from "../store/actions/userActions";

export const VideoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams(); // 获取URL中的id参数
  const { token, user } = useSelector((state) => state.user);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [previewResultObj, setPreviewResultObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});
  const [likes, setLikes] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [showAD, setShowAD] = useState(true);
  const playerRef = useRef(null);
  const { t } = useTranslation();
  const advs = useSelector((state) => getAdvByPosition(state, PLAYER_ADV_POS));
  const { seconds, isRunning, start } = useTimer({
    expireTime: 3,
    autostart: false,
    countdown: true,
  });

  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  }, []);

  const loadVideoDetail = useCallback(async () => {
    try {
      const res = await getVideoDetail(id);
      setVideoInfo(res);
    } catch (e) {
      // alert(e);
    }
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const res = await guessWhatILike(id, pageNum, 10);
      setLoading(false);
      setHasNextPage(res.length >= 10);
      setLikes((likes) => {
        const newVideos = res.filter((v) => !likes?.some((a) => v.id === a.id));
        return [...likes, ...newVideos];
      });
    };
    loadData();
  }, [id, pageNum]);

  useEffect(() => {
    (async () => {
      try {
        start();
        setShowAD(advs.length > 0);
        setShowPreviewDialog(false);
        setPageNum(1);
        setLikes([]);
        if(advs.length > 0){
          loadVideoDetail();
        }else{
          closeAD()
        }
      } catch (error) {}
    })();
  }, [id, loadVideoDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMore = useCallback(async () => {
    if (hasNextPage) {
      setPageNum(pageNum + 1);
    }
  }, [pageNum, hasNextPage]);

  const [sentryRef] = useInfiniteScroll({
    onLoadMore: loadMore,
    loading,
    hasNextPage,
    rootMargin: "0px 0px 300px 0px",
  });

  const closeAD = useCallback(async () => {
    if (isRunning) return;
    const res = await getVideoPlayUrl(id);
    if (res.code) {
      setPreviewResultObj(res);
      setShowPreviewDialog(true);
    } else {
      setVideoInfo((videoInfo) => ({ ...videoInfo, ...res }));
      dispatch(addVideoToWatchHistory(res));
    }
    setShowAD(false);
  }, [dispatch, id, isRunning]);

  const jumpAction = useCallback(
    (id) => {
      navigate(`/video/${id}`, {
        state: { isFromVideo: true },
        replace: true,
      });
    },
    [navigate],
  );

  const playerOptions = useMemo(() => {
    return {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      poster: videoInfo.thum || bg_lblogo,
      height: 210,
      preload: "auto",
      sources: [
        {
          src: videoInfo.video,
          type: "video/mp4",
        },
      ],
    };
  }, [videoInfo]);

  const likeHandler = useCallback(async () => {
    if (token) {
      try {
        const res = await setAsfavorite(videoInfo.id);
        if (res.code === 401) {
          setPreviewResultObj({
            code: 1002,
            message: t("unlogin_info"),
          });
          setShowPreviewDialog(true);
        }
        if (res.code === 200) {
          setVideoInfo((v) => ({ ...v, collected: true }));
          dispatch(addVideoToFavorites(videoInfo));
        }
      } catch (e) {}
    } else {
      setPreviewResultObj({
        code: 1002,
        message: t("unlogin_info"),
      });
      setShowPreviewDialog(true);
    }
  }, [videoInfo, token, t, dispatch]);

  const unlikeHandler = useCallback(async () => {
    try {
      const res = await setAsfavorite(videoInfo.id);
      if (res.code === 200) {
        setVideoInfo((v) => ({ ...v, collected: false }));
      }
    } catch (e) {}
  }, [videoInfo.id]);

  const purchaseHandler = useCallback(async () => {
    try {
      const res = await buyVideo(videoInfo.id);
      if (res.code === 200) {
        closeAD();
      } else {
      }
    } catch (e) {}
  }, [videoInfo.id, closeAD]);

  return (
    <div>
      <div className="relative">
        {showPreviewDialog && (
          <VideoPreviewDialog
            resultObj={previewResultObj}
            videoObj={{...videoInfo, id:videoInfo.id || id}}
            refreshFunction={() => {
              alert("刷新页面的函数");
            }}
          />
        )}

        {!showAD && (
          <VideoJS options={playerOptions} onReady={handlePlayerReady} />
        )}

        {showAD && (
          <>
            <Image
              src={advs[0]?.pic_url}
              defaultPic={bg_lblogo}
              imgClassName="adv w-full object-cover h-[210px]"
              onClick={() => {
                jumpUrl(advs[0]?.jump_url);
              }}
              onLoad={() => {
                start();
              }}
            />
            <div
              onClick={closeAD}
              className="absolute top-4 right-4 w-8 h-8 rounded-full bg-black opacity-60 flex items-center justify-center text-white"
            >
              {isRunning ? `${seconds}s` : "❌"}
            </div>
          </>
        )}
        {videoInfo.preview && !showAD && user && user.vipLeftDays < 0 && (
          <div className="absolute top-0 right-0 text-white bg-black/80 w-full text-center text-sm">
            {t("video_preview_info_1")}&nbsp;
            <span
              className="text-carrot-slides-indicator-sel underline underline-offset-2"
              onClick={() => {
                navigate("/rechargeVIP");
              }}
            >
              {t("video_preview_info_2")}
            </span>
            &nbsp;{t("video_preview_info_3")}
          </div>
        )}

        <div
          onClick={() => {
            if (location.state?.isFromStartUp) {
              navigate("/index");
            } else {
              navigate(-1);
            }
          }}
          className="absolute z-10 top-4 left-4 w-8 h-8 rounded-full bg-black opacity-60 flex items-center justify-center"
        >
          <img src={ic_mute} className="w-6 h-6" alt="" />
        </div>
      </div>
      <div className="px-3 pb-1">
        <div className="flex justify-between text-white py-2 px-3 mt-4 bg-carrot-gray-40 rounded">
          <div>
            <span className="text-carrot-gray mr-2">
              {t("video_price_label")}
            </span>
            <span className="text-carrot-active">${videoInfo.price}</span>
          </div>
          {videoInfo.purchased && (
            <div className="text-carrot-yellow">
              {t("video_purchased_text")}
            </div>
          )}
          {!videoInfo.purchased && videoInfo.price !== 0 && (
            <div
              className="bg-carrot-yellow px-2 rounded text-white drop-shadow"
              onClick={purchaseHandler}
            >
              {t("video_purchase_button_text")}
            </div>
          )}
          {!videoInfo.purchased &&
            videoInfo.price === 0 &&
            !videoInfo.preview && (
              <div className=" px-2 rounded text-carrot-yellow drop-shadow">
                {t("video_free_tip")}
              </div>
            )}
        </div>
        <div className="bg-carrot-gray-10 px-3 py-3.5 my-4 rounded">
          <div className="flex text-white text-lg font-bold">
            <div className="grow">{videoInfo.name}</div>
            {videoInfo.collected && (
              <div className="w-11 h-11 grow-0 shrink-0 flex justify-center items-center">
                <img
                  src={ic_heart}
                  alt=""
                  className="w-7 h-7"
                  onClick={unlikeHandler}
                />
              </div>
            )}
            {!videoInfo.collected && (
              <img
                src={ic_like}
                className="w-11 h-11 grow-0"
                alt=""
                onClick={likeHandler}
              />
            )}
          </div>
          <div className="flex my-2">
            <div className="flex items-center">
              <img src={ic_instructor} className="w-3 h-3" alt="" />
              <span className="text-[12px] text-carrot-deactive ml-1">
                {videoInfo.views}
              </span>
            </div>
            <div className="flex items-center ml-8">
              <img src={ic_time} alt="" className="w-4 h-4" />
              <span className="text-[12px] text-carrot-deactive ml-1">
                {secondsToHHMMSS(videoInfo.duration)}
              </span>
            </div>
          </div>
          {/* <div className="text-sm text-carrot-deactive"> */}
          {/*   纪录片是对真实客观世界的艺术展现。 */}
          {/* </div> */}
          {/* <div className="flex my-2 space-x-2"> */}
          {/*   <div className="text-[12px] text-carrot-deactive"> */}
          {/*     #<span className="ml-1">绝佳好片</span> */}
          {/*   </div> */}
          {/*   <div className="text-[12px] text-carrot-deactive"> */}
          {/*     #<span className="ml-1">纯正美音</span> */}
          {/*   </div> */}
          {/*   <div className="text-[12px] text-carrot-deactive"> */}
          {/*     #<span className="ml-1">初学者</span> */}
          {/*   </div> */}
          {/*   <div className="text-[12px] text-carrot-deactive"> */}
          {/*     #<span className="ml-1">必看</span> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
        <CardGrid
          cardDirection="horizontal"
          heading={"猜你喜欢"}
          id={123}
          items={likes}
          jumpAction={jumpAction}
        />
        {(loading || hasNextPage) && (
          <div ref={sentryRef} className="text-white text-center h-7">
            {t("video_loading")}
          </div>
        )}
      </div>
    </div>
  );
};
