import successIcon from "../assets/pay/pay_success.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PaymentSuccessPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const returnHomeClicked = () => {
        navigate(`/profile`);
    };

    const balanceDetailClicked = () => {
        navigate(`/balanceDetails`);
    };

    return (
    <div className="h-screen text-center flex flex-col items-center pt-[30%]">
        <img src={successIcon} className="w-4/12" />
        <p className='text-xl mt-5 text-[#66B85A]'>{t("pay_success")} !!!</p>
        <div className="flex flex-row mt-40">
            <button onClick={balanceDetailClicked} className="bg-[#66B85A] text-white py-2 px-5 rounded" >
                {t("balance_stat")}
            </button>

            <button onClick={returnHomeClicked} className="bg-[#66B85A] text-white py-2 px-5 rounded ml-5" >
                {t("return_home")}
            </button>
        </div>
    </div>
    );
};
